import { Link } from 'react-scroll';
import { MapPin, DownloadSimple, GithubLogo, LinkedinLogo, ArrowDownRight } from '@phosphor-icons/react';

import './Portfolio.css'
import myCV from './assets/Jack-Searle_CV.pdf';
import Contact from './Contact.js';


function Portfolio() {
    // const [isNightMode, setIsNightMode] = useState(false);
    // const toggleNightMode = (event) => {
    //     event.preventDefault();
    //     setIsNightMode(!isNightMode);
    // };

    return (
        // <div className={`Portfolio ${isNightMode ? 'night-mode' : 'day-mode'}`}>
        <div className='Portfolio'>
            <div className='content'>
                <div className='profile'>
                    <div className='intro-panel'>
                        <div className='intro'>
                            <p className='intro-greeting'><span>👋</span> Hello world. I'm</p>
                            <h1 className='full-name'>Jack Searle</h1>
                            {/* <p className='bio'>I design and develop web, software, and games.</p> */}
                        </div>
                        <div className='shortcuts'>
                            <Link to='about-section' className='shortcut-link' offset={-100} smooth={true} duration={300}>
                                <ArrowDownRight size={26} className='shortcut-arrow' />
                                <span>About</span>
                            </Link>
                            <Link to='experience-section' className='shortcut-link' offset={-100} smooth={true} duration={300}>
                                <ArrowDownRight size={26} className='shortcut-arrow' />
                                <span>Experience</span>
                            </Link>
                            <Link to='project-section' className='shortcut-link' offset={-100} smooth={true} duration={300}>
                                <ArrowDownRight size={26} className='shortcut-arrow' />
                                <span>Projects</span>
                            </Link>
                            <Link to='contact-section' className='shortcut-link' offset={-100} smooth={true} duration={300}>
                                <ArrowDownRight size={26} className='shortcut-arrow' />
                                <span>Contact</span>
                            </Link>
                        </div>
                        <p className='location'><MapPin size={32} className='location-pin' /> Christchurch, NZ</p>
                        <div className='ext-links'>
                            <a href={myCV} download='Jack-Searle_CV' className='CV-download'>
                                <DownloadSimple size={32} color='#F5F5F5' className='CV-download-icon'/>
                                <p> Download CV</p>
                            </a>
                            <a href='https://www.github.com/jcksrl' target='_blank' rel="noreferrer" className='social-link'><GithubLogo size={40} className='social-icon' /></a>
                            <a href='https://www.linkedin.com/in/jack-searle-587a8b290/' target='_blank' rel="noreferrer" className='social-link'><LinkedinLogo size={40} className='social-icon' /></a>
                            {/* <a href='https://www.twitter.com' target='_blank' rel="noreferrer" className='social-link'><TwitterLogo size={32} /></a> */}
                        </div>
                    </div>
                </div>
                <div className='resume'>
                    <div className='section' id='about-section'>
                        <h2 className='section-header'>About Me</h2>
                        <p>
                            I design and develop web, software and games.<br /><br />
                            For as long as I can remember I've always had an interest in technology, which lead me to pick up coding as a hobby during highschool.
                            Since then, I've studied and practiced my craft in order to make a career from which I love.<br /><br />
                            When I'm away-from-keyboard I'm likely listening to music, watching a film, enjoying time with friends and family, or playing video games.
                        </p>
                    </div>
                    <div className='section' id='experience-section'>
                        <h2 className='section-header'>Relevant Experience</h2>
                        <div className='timeline'>
                            <div className='timeline-event'>
                                <div className='timeline-circle'></div>
                                <p className='timeline-date'>JAN '24 - APR '24</p>
                                <div className='exp-content'>
                                    <h3 className='exp-name'>Full Stack Development Intern</h3>
                                    <p className='exp-place'>Hero (Linc-Technologies)</p>
                                    <p className='exp-desc'>
                                        My main objective during this internship has been the research and development of
                                        implementing an AI assistant into Hero’s school administration platform. This project has
                                        involved making API calls and performing CRUD operations from a backend written in Go,
                                        developing an appealing and responsive frontend with Ember.js, prompt engineering, and
                                        onsite consulting with customer schools. After finishing the AI assistant early, I developed
                                        an alternative to a paid-for widget which showcases Hero’s latest updates and blogposts
                                        to their customers, saving them $12K annually. I have learnt a great deal from working as
                                        part of a successful development team and following their agile production processes.
                                    </p>
                                    <ul className='skill-tags'>
                                        <li>Ember.js</li>
                                        <li>GoLang</li>
                                        <li>GitHub</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='timeline-event'>
                                <div className='timeline-circle'></div>
                                <p className='timeline-date'>FEB '23 - NOV '23</p>
                                <div className='exp-content'>
                                    <h3 className='exp-name'>Programming Demonstrator</h3>
                                    <p className='exp-place'>University of Otago</p>
                                    <p className='exp-desc'>
                                        During my last year of study I was offered to demonstrate coding in Java to a first-year
                                        computer science course where I would guide students and provide assistance should they need it.
                                        I found this role to be extremely beneficial in my ability to communicate programming
                                        concepts while also improving my own coding competency by reinforcing the basics.
                                    </p>
                                    <ul className='skill-tags'>
                                        <li>Java</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='timeline-event'>
                                <div className='timeline-circle'></div>
                                <p className='timeline-date'>2020 - 2023</p>
                                <div className='exp-content'>
                                    <h3 className='exp-name'>Computer Science Major</h3>
                                    <p className='exp-place'>University of Otago</p>
                                    <p className='exp-desc'>
                                        Studying computer science at the University of Otago taught me a great deal about the fundamentals of programming. 
                                        Beyond the basics, some of the important concepts I studied were data structures, algorithms, AI, version control,
                                        CI/CD, game design, database management, and much, much more. Get in touch if you're interested in seeing my transcript.
                                    </p>
                                    <ul className='skill-tags'>
                                        <li>Python</li>
                                        <li>Java</li>
                                        <li>C#</li>
                                        <li>JavaScript</li>
                                        <li>C++</li>
                                        <li>C</li>
                                        <li>GitLab</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='section' id='project-section'>
                        <h2 className='section-header'>Projects</h2>
                        <div className='project'>
                            <h3 className='project-name'>Login Languish</h3>
                            <p className='project-desc'>
                                Login Languish is an infuriating yet humorous game mocking the unforgiving nature of account signup processes.
                                We worked on this project as a team of four students for a Software Engineering course during my last semester of study.
                                It uses Ultralight-SDK to display a React frontend which is hooked up to a backend written in C++ largely consisting of input validation and unit testing.
                            </p>
                            <ul className='skill-tags'>
                                <li>C++</li>
                                <li>React.js</li>
                                <li>GitHub</li>
                            </ul>
                        </div>
                        <div className='project'>
                            <h3 className='project-name'>A.N.D.I.E.</h3>
                            <p className='project-desc'>
                                A Non-Destructive Image Editor (ANDIE) is a basic image editing software equipped with standard tools such as cropping, rotating, applying filters, and drawing shapes.
                                This was another group project I undertook as part of a Software Development course.
                                ANDIE was my first programming group project and served as an introduction to collaborative Git repositiories and resolving merge conflicts.
                            </p>
                            <ul className='skill-tags'>
                                <li>Java</li>
                                <li>GitLab</li>
                            </ul>
                        </div>
                    </div>
                    <div className='section' id='contact-section'>
                        <h2 className='section-header'>Contact Me</h2>
                        <Contact />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio;